module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Manage","short_name":"Manage","start_url":"/","background_color":"#5A41F3","theme_color":"#5A41F3","display":"minimal-ui","icon":"src/images/logo-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c585486219790f577fe1fc0a96965d22"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KL8LCSJ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{}},"dataLayerName":"MANAGEWEBSITE","routeChangeEventName":"PAGE_CHANGE"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
